import React from "react";

function LaptopTextbookIcon() {
  return (
    <svg
      width="150%"
      height="150%"
      version="1.1"
      viewBox="0 0 700 700"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="#f3f3f3"
    >
      <g>
        <path d="m539.15 288.23h-36.027v-225.18c0-24.832-20.203-45.035-45.035-45.035h-288.23c-24.832 0-45.039 20.203-45.039 45.035v225.18h-36.027c-4.9727 0-9.0078 4.0273-9.0078 9.0078 0 24.832 20.203 45.035 45.035 45.035h189.15v207.17c0 4.9805 4.0352 9.0078 9.0078 9.0078h216.17c4.9727 0 9.0078-4.0273 9.0078-9.0078l0.003906-252.21c0-4.9805-4.0352-9.0039-9.0078-9.0039zm-396.32-225.18c0-14.898 12.125-27.023 27.023-27.023h288.23c14.898 0 27.023 12.125 27.023 27.023v225.18h-342.28zm-18.016 261.21c-11.746 0-21.762-7.5312-25.48-18.016h214.63v18.016zm207.17 9.0078v-27.023h18.016v234.19h-18.016zm198.16 207.17h-162.13v-234.19h162.13z" />
        <path d="m574.09 323.17c-5.1094 5.0977-7.918 11.891-7.918 19.105v172.69c0 1.3945 0.32422 2.7852 0.94531 4.0273l18.016 36.027c1.5234 3.0547 4.6484 4.9805 8.0625 4.9805 3.4141 0 6.5312-1.9258 8.0625-4.9805l18.016-36.027c0.61719-1.2539 0.94141-2.6328 0.94141-4.0273v-172.69c0-7.2148-2.8086-14.008-7.9102-19.105-10.215-10.203-28.012-10.203-38.215 0.003906zm10.094 19.105c0-2.3672 0.96484-4.6914 2.6406-6.3672 3.3945-3.4141 9.332-3.4141 12.734 0 1.6758 1.6719 2.6406 3.9961 2.6406 6.3672v18.016h-18.016zm18.016 136.66h-18.016v-100.63h18.016zm-9.0078 51.918-9.0078-18.016v-15.891h18.016v15.891z" />
        <path d="m386.03 378.3v54.043c0 4.9805 4.0352 9.0078 9.0078 9.0078h108.09c4.9727 0 9.0078-4.0273 9.0078-9.0078v-54.043c0-4.9805-4.0352-9.0078-9.0078-9.0078h-108.09c-4.9727 0-9.0078 4.0273-9.0078 9.0078zm18.016 9.0078h90.074v36.027h-90.074z" />
        <path d="m196.88 117.09c0 3.2969 1.8008 6.332 4.6914 7.9102l40.348 22.004v51.152c0 3.8203 2.4141 7.2227 6.0078 8.4922 15.348 5.4141 31.336 8.6016 47.531 9.5039 3.1797 0.17969 6.3516 0.26172 9.5117 0.26172 19.348 0 38.48-3.2695 57.016-9.7656 3.6094-1.25 6.0352-4.6641 6.0352-8.4922v-51.152l27.023-14.734v40.531c-10.457 3.7305-18.016 13.637-18.016 25.363v27.023c0 4.9805 4.0352 9.0078 9.0078 9.0078h36.027c4.9727 0 9.0078-4.0273 9.0078-9.0078v-27.023c0-11.727-7.5586-21.637-18.016-25.363v-55.711c0-3.2969-1.8008-6.332-4.6914-7.9102l-99.078-54.043c-2.6914-1.4609-5.9375-1.4609-8.6289 0l-99.078 54.043c-2.8945 1.5781-4.6992 4.6133-4.6992 7.9102zm153.12 39.742v34.797c-17.445 5.3398-35.461 7.4922-53.539 6.5469-12.387-0.69531-24.645-2.8828-36.535-6.5391v-34.805l40.723 22.211c1.3438 0.73047 2.8281 1.0977 4.3164 1.0977 1.4844 0 2.9727-0.37109 4.3164-1.0977zm63.051 59.34h-18.016v-18.016c0-4.9727 4.043-9.0078 9.0078-9.0078 4.9648 0 9.0078 4.0352 9.0078 9.0078zm-108.09-142.87 80.273 43.785-80.273 43.785-80.273-43.785z" />
        <path d="m467.09 261.21v-198.16c0-4.9805-4.0352-9.0078-9.0078-9.0078h-90.07v18.016h81.066v180.14h-270.22v-180.14h63.051v-18.016h-72.059c-4.9727 0-9.0078 4.0273-9.0078 9.0078v198.16c0 4.9805 4.0352 9.0078 9.0078 9.0078h288.23c4.9688 0 9.0039-4.0273 9.0039-9.0078z" />
        <path d="m584.19 279.23h18.016v18.016h-18.016z" />
        <path d="m584.19 243.2h18.016v18.016h-18.016z" />
        <path d="m584.19 207.17h18.016v18.016h-18.016z" />
        <path d="m584.19 171.14h18.016v18.016h-18.016z" />
        <path d="m584.19 135.11h18.016v18.016h-18.016z" />
        <path d="m548.16 135.11h18.016v18.016h-18.016z" />
        <path d="m259.93 450.36h18.016v18.016h-18.016z" />
        <path d="m223.9 450.36h18.016v18.016h-18.016z" />
        <path d="m187.87 450.36h18.016v18.016h-18.016z" />
        <path d="m151.84 450.36h18.016v18.016h-18.016z" />
        <path d="m151.84 414.34h18.016v18.016h-18.016z" />
        <path d="m151.84 378.3h18.016v18.016h-18.016z" />
      </g>
    </svg>
  );
}

export default LaptopTextbookIcon;
