import i18n from "../../i18n";

export default [
  {
    day: i18n.t("monday"),
    gevmaKirios: [
      i18n.t("porkBitesWithPeppersAndMushrooms"),
      i18n.t("porkSteakWithMustard"),
      i18n.t("xoirinoKokkinisto"),
      i18n.t("xoirinoSouvlaki"),
      i18n.t("kototiganiaAlaKrem"),
    ],
    gevmaEidiko: [i18n.t("mpiftekiLahanikon")],
    gevmaGarnitoura: [i18n.t("rizoto")],
    gevmaSalata: [i18n.t("choiceOfSeasonalSalads")],
    gevmaEpidorpio: [i18n.t("choiceOfSeasonalFruits")],
    deipnoKirios: [
      i18n.t("fasoliaLadera"),
      i18n.t("mpriam"),
      i18n.t("lahanorizo"),
    ],
    deipnoEidiko: [i18n.t("vegetarian")],
    deipnoGarnitoura: [i18n.t("gratedCheese")],
    deipnoSalata: [i18n.t("choiceOfSeasonalSalads")],
    deipnoEpidorpio: [i18n.t("choiceOfSeasonalFruits")],
  },
  {
    day: i18n.t("tuesday"),
    gevmaKirios: [
      i18n.t("kotompoukiesAlaKrem"),
      i18n.t("mpoutiKotLemonato"),
      i18n.t("moshariNouaMeNtomata"),
      i18n.t("mpiftekiKotMeMoustarda"),
      i18n.t("roloGalopoulas"),
    ],
    gevmaEidiko: [i18n.t("gemistaLadera")],
    gevmaGarnitoura: [i18n.t("kritharakiIrizi")],
    gevmaSalata: [i18n.t("choiceOfSeasonalSalads")],
    gevmaEpidorpio: [i18n.t("choiceOfSeasonalFruits")],
    deipnoKirios: [
      i18n.t("cheesepie"),
      i18n.t("spinachpie"),
      i18n.t("mushroompieWithFyllo"),
      i18n.t("scrammbledEggs"),
    ],
    deipnoEidiko: [i18n.t("vegetarian")],
    deipnoGarnitoura: [i18n.t("fetaCheese")],
    deipnoSalata: [i18n.t("choiceOfSeasonalSalads")],
    deipnoEpidorpio: [i18n.t("choiceOfSeasonalFruits")],
  },
  {
    day: i18n.t("wednesday"),
    gevmaKirios: [
      i18n.t("fakes"),
      i18n.t("fasolada"),
      i18n.t("revithada"),
      i18n.t("fakorizo"),
    ],
    gevmaEidiko: [i18n.t("kolokithokeftedes")],
    gevmaGarnitoura: [""],
    gevmaSalata: [i18n.t("choiceOfSeasonalSalads")],
    gevmaEpidorpio: [i18n.t("gliko")],
    deipnoKirios: [
      i18n.t("makaroniaKarbonara"),
      i18n.t("makaroniaMeSaltsa"),
      i18n.t("makaroniaTrikolore"),
    ],
    deipnoEidiko: [i18n.t("vegetarian")],
    deipnoGarnitoura: [i18n.t("makaronosalata")],
    deipnoSalata: [i18n.t("choiceOfSeasonalSalads")],
    deipnoEpidorpio: [i18n.t("choiceOfSeasonalFruits")],
  },
  {
    day: i18n.t("thursday"),
    gevmaKirios: [
      i18n.t("pastitsio"),
      i18n.t("mpiftekiBeefMeKaseri"),
      i18n.t("soutzoukakiaNtomata"),
      i18n.t("keftedakiaKotopoulo"),
      i18n.t("xoirinoEksohiko"),
    ],
    gevmaEidiko: [i18n.t("spanakorizo")],
    gevmaGarnitoura: [i18n.t("frenchFriesAndRice")],
    gevmaSalata: [i18n.t("choiceOfSeasonalSalads")],
    gevmaEpidorpio: [i18n.t("choiceOfSeasonalFruits")],
    deipnoKirios: [
      i18n.t("arakas"),
      i18n.t("patatesGiahni"),
      i18n.t("spanakorizo"),
    ],
    deipnoEidiko: [i18n.t("vegetarian")],
    deipnoGarnitoura: [i18n.t("fetaCheese")],
    deipnoSalata: [i18n.t("choiceOfSeasonalSalads")],
    deipnoEpidorpio: [i18n.t("choiceOfSeasonalFruits")],
  },
  {
    day: i18n.t("friday"),
    gevmaKirios: [
      i18n.t("gigantes"),
      i18n.t("galeos"),
      i18n.t("soupies"),
      i18n.t("makaroniaKarbonara"),
    ],
    gevmaEidiko: [i18n.t("kritharotoThalassinon")],
    gevmaGarnitoura: [i18n.t("riziKaiPatatesFournou")],
    gevmaSalata: [i18n.t("choiceOfSeasonalSalads")],
    gevmaEpidorpio: [i18n.t("choiceOfSeasonalFruits")],
    deipnoKirios: [
      i18n.t("pitsaSpecial"),
      i18n.t("smokedTurkey"),
      i18n.t("pitsaMesogeiaki"),
      i18n.t("tiriKaiLahanika"),
      i18n.t("pitsaMeKima"),
      i18n.t("pitsaMeKotopoulo"),
    ],
    deipnoEidiko: [i18n.t("vegetarian")],
    deipnoGarnitoura: [i18n.t("makaronosalata")],
    deipnoSalata: [i18n.t("choiceOfSeasonalSalads")],
    deipnoEpidorpio: [i18n.t("choiceOfSeasonalFruits")],
  },
  {
    day: i18n.t("saturday"),
    gevmaKirios: [
      i18n.t("tiganiaKotLemonati"),
      i18n.t("mpoutiKotPsito"),
      i18n.t("xoirinoPrasoselino"),
      i18n.t("soutzoukakiaNtomataKimino"),
    ],
    gevmaEidiko: [i18n.t("falafel")],
    gevmaGarnitoura: [i18n.t("pouresKaiKouskous")],
    gevmaSalata: [i18n.t("choiceOfSeasonalSalads")],
    gevmaEpidorpio: [i18n.t("choiceOfSeasonalFruits")],
    deipnoKirios: [
      i18n.t("kotosoupaAvgolemono"),
      i18n.t("soufleZimTyria"),
      i18n.t("mpiftekiLahanikon"),
    ],
    deipnoEidiko: [i18n.t("vegetarian")],
    deipnoGarnitoura: [i18n.t("gratedCheese")],
    deipnoSalata: [i18n.t("choiceOfSeasonalSalads")],
    deipnoEpidorpio: [i18n.t("choiceOfSeasonalFruits")],
  },
  {
    day: i18n.t("sunday"),
    gevmaKirios: [
      i18n.t("xoiriniPansetaSxaras"),
      i18n.t("mprizolesXoirLemonates"),
      i18n.t("soublakiXoirTzatzFeta"),
      i18n.t("filetoMpoutiKotSxaras"),
    ],
    gevmaEidiko: [i18n.t("Lahanorizo")],
    gevmaGarnitoura: [i18n.t("riziIpatatesTiganites")],
    gevmaSalata: [i18n.t("choiceOfSeasonalSalads")],
    gevmaEpidorpio: [i18n.t("glyko")],
    deipnoKirios: [
      i18n.t("loukanikoXor"),
      i18n.t("loukanikoVrasto"),
      i18n.t("omeletaZamponFeta"),
    ],
    deipnoEidiko: [i18n.t("vegetarian")],
    deipnoGarnitoura: [i18n.t("patatesFournou")],
    deipnoSalata: [i18n.t("choiceOfSeasonalSalads")],
    deipnoEpidorpio: [i18n.t("choiceOfSeasonalFruits")],
  },
];
