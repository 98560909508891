export default {
    page_title:"Συχνές Ερωτήσεις",
    page_desc:"Εδώ θα βρείτε απαντήσεις σε ερωτήσεις που ενδέχεται να σας ενδιαφέρουν.",
    qu1: "Σε ποιους απευθύνεται η εφαρμογή;",
    ans1:"Απευθύνεται σε όλους τους φοιτητές/φοιτήτριες του πανεπιστημίου καθώς και στο διδακτικό και διοικητικό προσωπικό.",
    qu2: "Τι μπορώ να βρω στην εφαρμογή;",
    ans2: "Η εφαρμογή παρουσιάζει στο κινητό όλες τις υπηρεσίες του πανεπιστημίου καθώς και ό,τι χρειάζεται ένας φοιτητής/φοιτήτρια κατά τη διάρκεια της φοιτητικής ζωής.",
    qu3: "Από πού μπορώ να εγκαταστήσω την εφαρμογή;",
    ans3: "Η εφαρμογή μπορεί να προστεθεί στην αρχική σας οθόνη. Επιλέξτε τον browser σας, πατήστε στο μενού δεξιά, και μετά επιλέξτε 'Add to Home Screen'. Θα γίνει αυτόματη εγκατάσταση της εφαρμογής, στη συσκευή σας. ΠΡΟΣΟΧΗ! Για χρήστες iPhone, το συγκεκριμένο λειτουργεί μόνο στο Safari. Διαφορετικά, κρατήστε την ιστοσελίδα στους σελιδοδείκτες σας.",
    qu4: "Η εφαρμογή δουλεύει offline;",
    ans4: "Προς το παρόν η εφαρμογή χρειάζεται να είστε συνδεδεμένοι/ες στο διαδίκτυο για να μπορέσει να σας παρουσιάσει τα δεδομένα που έχετε αναζητήσει. Σε επόμενη έκδοση της εφαρμογής πιθανόν να μη χρειάζεται διαδίκτυο για τη λειτουργία της. ",
    qu5: "Η εφαρμογή είναι δωρεάν;",
    ans5: "Η εφαρμογή διατίθεται δωρεάν για όλους τους φοιτητές/φοιτήτριες και το προσωπικό του πανεπιστημίου.",
    qu6: "Ποιοι κατασκεύασαν την εφαρμογή;",
    ans6: "Η εφαρμογή κατασκευάστηκε από την Ομάδα Ανοικτού Λογισμικού του Πανεπιστημίου Μακεδονίας.",
    qu7: "Πού κρατούνται τα δεδομένα μου;",
    ans7: "Δεν αποθηκεύονται προσωπικά σας δεδομένα σε κάποιον διακομιστή του πανεπιστημίου. Αποθηκεύεται στη συσκευή σας η σχολή που θα επιλέξετε κατά την είσοδό σας στην εφαρμογή ώστε να σας παρουσιάζονται τα δεδομένα που αφορούν τη σχολή σας και μόνο.",
};