export default {
    orario: "Schedule",
    defPar: "Mon-Fri",
    savvato: "Saturday",
    kyriaki: "Sunday",
    epikoinonia: "Contact Info",
    imiorofos: "Mezzanine",
    istoselidaVivliothikis: "Library's website",
    kleista: "Closed"

};
