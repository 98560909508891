import React from "react";

function ManyPeopleIcon() {
  return (
    <svg
      width="150%"
      height="150%"
      version="1.1"
      viewBox="0 -60 600 600"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="#f3f3f3"
    >
      <path
        d="M98.113 168.125c-31.73 0-57.543-25.578-57.543-57.008 0-31.433 25.813-57.012 57.543-57.012 31.727 0 57.535 25.575 57.535 57.012 0 31.434-25.808 57.008-57.535 57.008zm0-98.02c-22.91 0-41.543 18.399-41.543 41.012 0 22.61 18.633 41.008 41.543 41.008 22.903 0 41.535-18.398 41.535-41.008 0-22.613-18.632-41.012-41.535-41.012zm23.149 255.864H20.91C9.38 325.969 0 316.649 0 305.19v-26.168c0-53.57 44.012-97.156 98.113-97.156 21.278 0 41.532 6.64 58.555 19.2a8.004 8.004 0 011.687 11.19 8.006 8.006 0 01-11.187 1.688c-14.254-10.515-31.215-16.078-49.055-16.078-45.277 0-82.113 36.41-82.113 81.156v26.168c0 2.633 2.203 4.778 4.91 4.778h100.356c4.414 0 8 3.582 8 8 0 4.414-3.586 8-8.004 8zm353.828-6.055H374.738c-4.414 0-8-3.582-8-8 0-4.414 3.586-8 8-8h100.356c2.707 0 4.91-2.14 4.91-4.773v-26.168c0-44.754-36.836-81.157-82.113-81.157-18.91 0-37.371 6.512-51.985 18.34-3.43 2.778-8.465 2.25-11.25-1.183-2.777-3.434-2.254-8.473 1.188-11.254 17.449-14.121 39.484-21.903 62.047-21.903 54.097 0 98.109 43.582 98.109 97.157v26.168c0 11.453-9.379 20.773-20.91 20.773zm-77.203-155.742c-31.727 0-57.535-25.574-57.535-57.008s25.808-57.012 57.535-57.012c31.73 0 57.543 25.575 57.543 57.012s-25.817 57.008-57.543 57.008zm0-98.02c-22.903 0-41.535 18.399-41.535 41.012s18.632 41.008 41.535 41.008c22.91 0 41.543-18.395 41.543-41.008 0-22.613-18.637-41.012-41.543-41.012zm0 0"
        data-original="#000000"
      ></path>
      <path
        d="M356.578 352H139.422c-14.426 0-26.16-11.648-26.16-25.969v-36.804c0-73.555 60.437-133.395 134.738-133.395s134.738 59.84 134.738 133.395v36.804c0 14.32-11.734 25.969-26.16 25.969zM248 171.836c-65.473 0-118.738 52.664-118.738 117.394v36.805c0 5.496 4.558 9.969 10.16 9.969h217.152c5.602 0 10.16-4.473 10.16-9.969V289.23c.004-64.73-53.261-117.394-118.734-117.394zm-2.781-25.352c-40.766 0-73.938-32.855-73.938-73.246C171.281 32.852 204.45 0 245.22 0c40.773 0 73.941 32.855 73.941 73.242 0 40.39-33.172 73.242-73.941 73.242zm0-130.484c-31.946 0-57.938 25.68-57.938 57.242 0 31.567 25.992 57.246 57.938 57.246 31.949 0 57.941-25.68 57.941-57.246C303.16 41.68 277.168 16 245.22 16zm0 0"
        data-original="#000000"
      ></path>
    </svg>
  );
}

export default ManyPeopleIcon;
