import i18n from "../../../../../i18n";
import { FOURTH_FLOOR_HT_IMG } from "../../floor_images";
import elevator from "../../elevator_positions";

let fourth_floor_locations;
export default fourth_floor_locations = [
  {
    floor: i18n.t("fourth_floor"),
    imageURL: FOURTH_FLOOR_HT_IMG,
    ...elevator.gym,
    title: "402",
    marked_position_x: 21,
    marked_position_y: 165,
  },
  {
    floor: i18n.t("fourth_floor"),
    imageURL: FOURTH_FLOOR_HT_IMG,
    ...elevator.gym,
    title: "403",
    marked_position_x: 26,
    marked_position_y: 146,
  },
  {
    floor: i18n.t("fourth_floor"),
    imageURL: FOURTH_FLOOR_HT_IMG,
    ...elevator.gym,
    title: "405",
    marked_position_x: 37,
    marked_position_y: 130,
  },
  {
    floor: i18n.t("fourth_floor"),
    imageURL: FOURTH_FLOOR_HT_IMG,
    ...elevator.gym,
    title: "406",
    marked_position_x: 27,
    marked_position_y: 102,
  },
  {
    floor: i18n.t("fourth_floor"),
    imageURL: FOURTH_FLOOR_HT_IMG,
    ...elevator.gym,
    title: "407",
    marked_position_x: 52,
    marked_position_y: 99,
  },
  {
    floor: i18n.t("fourth_floor"),
    imageURL: FOURTH_FLOOR_HT_IMG,
    ...elevator.gym,
    title: "408",
    marked_position_x: 72,
    marked_position_y: 98,
  },
  {
    floor: i18n.t("fourth_floor"),
    imageURL: FOURTH_FLOOR_HT_IMG,
    ...elevator.gym,
    title: "410",
    marked_position_x: 90,
    marked_position_y: 96,
  },
  {
    floor: i18n.t("fourth_floor"),
    imageURL: FOURTH_FLOOR_HT_IMG,
    ...elevator.gym,
    title: "411",
    marked_position_x: 107,
    marked_position_y: 102,
  },
  {
    floor: i18n.t("fourth_floor"),
    imageURL: FOURTH_FLOOR_HT_IMG,
    ...elevator.gym,
    title: "412",
    marked_position_x: 141,
    marked_position_y: 104,
  },
  // {
  //   floor: i18n.t("fourth_floor"),
  //   imageURL: FOURTH_FLOOR_HT_IMG,
  //   title: "430",
  //   marked_position_x: 303,
  //   marked_position_y: 146,
  // },
  // {
  //   floor: i18n.t("fourth_floor"),
  //   imageURL: FOURTH_FLOOR_HT_IMG,
  //   title: "431",
  //   marked_position_x: 299,
  //   marked_position_y: 195,
  // },
  // {
  //   floor: i18n.t("fourth_floor"),
  //   imageURL: FOURTH_FLOOR_HT_IMG,
  //   title: "432",
  //   marked_position_x: 315,
  //   marked_position_y: 163,
  // },
  // {
  //   floor: i18n.t("fourth_floor"),
  //   imageURL: FOURTH_FLOOR_HT_IMG,
  //   title: "433",
  //   marked_position_x: 268,
  //   marked_position_y: 187,
  // },
  // {
  //   floor: i18n.t("fourth_floor"),
  //   imageURL: FOURTH_FLOOR_HT_IMG,
  //   title: "434",
  //   marked_position_x: 225,
  //   marked_position_y: 180,
  // },
  // {
  //   floor: i18n.t("fourth_floor"),
  //   imageURL: FOURTH_FLOOR_HT_IMG,
  //   title: "435",
  //   marked_position_x: 189,
  //   marked_position_y: 182,
  // },
  // {
  //   floor: i18n.t("fourth_floor"),
  //   imageURL: FOURTH_FLOOR_HT_IMG,
  //   title: "436",
  //   marked_position_x: 187,
  //   marked_position_y: 136,
  // },
];
