import React from "react";

function GraduationIcon() {
  return (
    <svg
      width="150%"
      height="150%"
      version="1.1"
      viewBox="0 0 700 700"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="none"
      stroke="#f3f3f3"
      strokeWidth="14"
    >
      <g
        strokeLinecap="round"
        transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
      >
        <path d="M108.94 299.204c-2.168-11.628-4.494-26.078-6.475-42.847M413.124 216.072c-2.063 34.089-6.229 62.441-9.953 82.533M171.262 138.775c12.845-3.617 27.45-6.854 43.882-9.284M99.357 223.413a714.924 714.924 0 01-1.771-45.19 10.34 10.34 0 014.356-8.528c3.898-2.76 10.436-7.002 19.752-11.796M354.823 285.25l142.602 47.158c9.43 3.118 9.43 16.458 0 19.577l-234.953 77.698a20.623 20.623 0 01-12.948 0L14.573 351.985c-9.431-3.119-9.431-16.459 0-19.577l234.951-77.698a20.61 20.61 0 0112.948 0l60.754 20.091"></path>
        <path d="M282.275 354.146c0-9.958-11.809-13.906-26.374-13.906-14.566 0-26.374 3.948-26.374 13.906s11.808 18.03 26.374 18.03c14.565 0 26.374-8.072 26.374-18.03z"></path>
        <path d="M233.126 346.125l-86.647-57.338v-30.559M134.57 218.15c-6.229-21.503-12.624-58.305-12.876-119.065a4.996 4.996 0 013.757-4.857c4.175-1.077 11.585-2.539 21.027-2.539s16.852 1.462 21.027 2.539a4.996 4.996 0 013.756 4.857c-.252 60.635-6.62 97.41-12.837 118.932"></path>
        <path d="M167.32 237.387c0-11.511-9.331-20.842-20.841-20.842-11.511 0-20.842 9.331-20.842 20.842 0 11.51 9.331 20.841 20.842 20.841 11.51 0 20.841-9.331 20.841-20.841zM249.914 81.95l189.959 69.14M471.2 162.49l23.471 8.542c9.931 3.615 12.814 20.748 6.437 38.268-6.377 17.519-19.596 28.791-29.529 25.176l-244.755-89.084"></path>
        <path d="M256.352 120.216c6.377-17.52 3.495-34.652-6.437-38.267-9.932-3.615-23.153 7.657-29.529 25.176-6.377 17.52-3.495 34.653 6.438 38.267 9.931 3.616 23.152-7.656 29.528-25.176zM365.838 124.142c9.933 3.615 12.814 20.748 6.437 38.267-6.375 17.52-19.596 28.792-29.528 25.177l34.072 12.401c9.932 3.615 23.153-7.657 29.53-25.177 6.376-17.519 3.494-34.651-6.438-38.267 0 0 32.719 4.709 46.009-20.569 1.399-2.662.082-5.953-2.743-6.981l-34.42-12.528c-2.597-.945-5.472.389-6.408 2.989-3.681 10.229-14.496 32.701-36.511 24.688zM399.911 136.543l-34.073-12.401"></path>
      </g>
    </svg>
  );
}

export default GraduationIcon;
