export default {
  open: "Ανοιχτά",
  closed: "Κλειστά",
  opensAt: "Ανοίγει στις",
  closesAt: "Κλείνει στις",
  breakfast: "Πρωινό",
  lunch: "Μεσημεριανό",
  dinner: "Βραδινό",
  mainDish: "Κυρίως",
  specialDish: "Χορτοφαγικό",
  salad: "Σαλάτα",
  dessert: "Επιδόρπιο",
  kyrios: "Κυρίως:",
  xortofagiko: "Χορτοφαγηκό:",
  salata: "Σαλάτα:",
  gevma: "Γεύμα",
  deipno: "Δείπνο",
  menou: "Μενού:",
  avriano: "Αυριανό",
  simerino: "Σημερινό",
  orario: "Ωράριο",
  proino: "Πρωινό:",
  mesimeriano: "Μεσημεριανό:",
  weekdays: "Καθημερινές",
  weekendsAndHolidays: "Σ/Κ και Αργίες",
  vradino: "Βραδινό:",
  olesOiMeres: "Όλες οι μέρες",
  monday: "ΔΕΥΤΕΡΑ",
  tuesday: "ΤΡΙΤΗ",
  wednesday: "ΤΕΤΑΡΤΗ",
  thursday: "ΠΕΜΠΤΗ",
  friday: "ΠΑΡΑΣΚΕΥΗ",
  saturday: "ΣΑΒΒΑΤΟ",
  sunday: "ΚΥΡΙΑΚΗ",
  choiceOfSeasonalFruits: "Επιλογή από φρούτα εποχής",
  choiceOfSeasonalSalads: "Επιλογή από σαλάτες εποχής",
  gratedCheese: "Τυρί τριμμένο",
  rizoto: "Ριζότο",
  vegetarian: "Χορτοφαγικό (ιατρικοί λόγοι)",
  porkBitesWithPeppersAndMushrooms: "Χοιρινές μπουκιές με πιπεριές & μανιτάρια",
  porkSteakWithMustard: "Χοιρινή μπριζόλα με σως μουστάρδας",
  xoirinoKokkinisto: "Χοιρινό κοκκινιστό σελινάτο",
  xoirinoSouvlaki: "Χοιρινό σουβλάκι",
  kototiganiaAlaKrem: "Κοτοτηγανιά αλά κρεμ",
  mpiftekiLahanikon: "Μπιφτέκι λαχανικών",
  fasoliaLadera: "Φασολάκια λαδερά",
  mpriam: "Μπριάμ",
  lahanorizo: "Λαχανόρυζο",
  kotompoukiesAlaKrem: "Κοτομπουκιές αλά κρεμ",
  mpoutiKotLemonato: "Μπούτι κοτόπουλου ψητό λεμονάτο",
  moshariNouaMeNtomata: "Μοσχαράκι νουά με φρέσκια ντομάτα",
  mpiftekiKotMeMoustarda: "Μπιφτέκι κοτόπουλο με σως μουστάρδας",
  roloGalopoulas: "Ρολό γαλοπούλας",
  gemistaLadera: "Γεμιστά λαδερά",
  kritharakiIrizi: "Κριθαράκι ή ρύζι",
  cheesepie: "Τυρόπιτα χωριάτικη",
  spinachpie: "Σπανακόπιτα χωριάτικη",
  mushroompieWithFyllo: "Μανιταρόπιτα νηστίσιμη με φύλλο κρούστας",
  scrammbledEggs: "Στραπατσάδα με ντομάτα ή φέτα",
  fetaCheese: "Τυρί φέτα",
  fakes: "Φακές σούπα με καρότο & ντομάτα",
  fasolada: "Φασόλια πηλιορείτικα με χωριάτικο λουκάνικο",
  revithada: "Ρεβυθάδα λεμονάτη",
  fakorizo: "Φακόρυζο με ντομάτα & φρέσκο κρεμμυδάκι",
  kolokithokeftedes: "Κολοκυθοκεφτέδες",
  gliko: "Γλυκό",
  makaroniaKarbonara: "Πέννες με κρέμα γάλακτος & μπέικον",
  makaroniaMeSaltsa: "Πέννες με σάλτσα μπολονέζ",
  makaroniaTrikolore: "Πέννες τρικολόρε με σάλτσα λαχανικών με κοτόπουλο",
  makaronosalata: "Μακαρονοσαλάτα",
  pastitsio: "Παστίτσιο",
  mpiftekiBeefMeKaseri: "Μπιφτέκι μόσχου με κασέρι",
  soutzoukakiaNtomata: "Σουτζουκάκια με σάλτσα ντομάτας",
  keftedakiaKotopoulo: "Κεφτεδάκια κοτόπουλου με βασιλικό & ντομάτα",
  xoirinoEksohiko: "Χοιρινό εξοχικό με φέτα & μανιτάρια",
  spanakorizo: "Σπανακόρυζο",
  frenchFriesAndRice: "Πατάτες τηγανητές & ρύζι",
  arakas: "Αρακάς με πατάτες & καρότα",
  patatesGiahni: "Πατάτες γιαχνί",
  gigantes: "Φασόλια γίγαντες με πιπεριές πολύχρωμες & βασιλικό",
  galeos: "Φέτες γαλεού φούρνου με σως μουστάρδας",
  soupies: "Σουπιές κρασάτες με πέννες τρικολόρε",
  kritharotoThalassinon: "Κριθαρότο θαλασσινών",
  riziKaiPatatesFournou: "Ρύζι & πατάτες φούρνου",
  pitsaSpecial: "Πίτσα σπέσιαλ με ζαμπόν",
  smokedTurkey: "Μπέικον & καπνιστή γαλοπούλα",
  pitsaMesogeiaki: "Πίτσα Μεσογειακή με γκούντα",
  tiriKaiLahanika: "Τυρί φέτα & λαχανικά",
  pitsaMeKima: "Πίτσα με κιμά & γκούντα",
  pitsaMeKotopoulo: "Πίτσα με κοτόπουλο & γκούντα",
  tiganiaKotLemonati: "Τηγανιά κοτόπουλου λεμονάτη",
  mpoutiKotPsito: "Μπούτι κοτόπουλο ψητό",
  xoirinoPrasoselino: "Χοιρινό πρασοσέλινο",
  soutzoukakiaNtomataKimino: "Σουτζουκάκια με ντομάτα & κύμινο",
  falafel: "Φαλάφελ",
  pouresKaiKouskous: "Πουρές πατάτας ή κους κους",
  kotosoupaAvgolemono: "Κοτόσουπα αυγολέμονο",
  soufleZimTyria: "Σουφλέ ζυμαρικών με τυριά",
  xoiriniPansetaSxaras: "Χοιρινή πανσέτα σχάρας",
  mprizolesXoirLemonates: "Μπριζόλες χοιρινές λεμονάτες",
  soublakiXoirTzatzFeta: "Σουβλάκι χοιρινό με τζατζίκι & πίτα",
  filetoMpoutiKotSxaras: "Φιλέτο μπούτι κοτόπουλου σχάρας",
  riziIpatatesTiganites: "Ρύζι ή πατάτες τηγανητές",
  loukanikoXor: "Λουκάνικο χωριάτικο",
  loukanikoVrasto: "Λουκάνικο βραστό",
  omeletaZamponFeta: "Ομελέτα με ζαμπόν & φέτα",
  patatesFournou: "Πατάτες φούρνου",
};
