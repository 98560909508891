export default {
orario: "Ωράριο",
defPar: "Δευ-Παρ",
savvato: "Σάββατο",
kyriaki: "Κυριακή",
epikoinonia: "Επικοινωνία",
imiorofos: "Ημιόροφος",
istoselidaVivliothikis: "Ιστοσελίδα Βιβλιοθήκης",
kleista: "Κλειστά"

};
