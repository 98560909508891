import React from "react";

function PCInterfaceIcon() {
  return (
    <svg
      width="150%"
      height="150%"
      version="1.1"
      viewBox="0 0 700 700"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="#f3f3f3"
    >
      <g>
        <path d="m595 131.25h-43.75v-113.75c0-4.8398-3.9219-8.75-8.75-8.75h-385c-4.8281 0-8.75 3.9102-8.75 8.75v113.75h-43.75c-14.473 0-26.25 11.777-26.25 26.25v262.5c0 14.473 11.777 26.25 26.25 26.25h175v35h-8.75c-33.773 0-61.25 27.484-61.25 61.25 0 4.8398 3.9219 8.75 8.75 8.75h262.5c4.8281 0 8.75-3.9102 8.75-8.75 0-33.766-27.477-61.25-61.25-61.25h-8.75v-35h175c14.473 0 26.25-11.777 26.25-26.25v-262.5c0-14.473-11.777-26.25-26.25-26.25zm-428.75-35h367.5v297.5h-367.5zm367.5-70v52.5h-367.5v-52.5zm-62.133 507.5h-243.23c4.0586-19.941 21.742-35 42.867-35h157.5c21.133 0 38.805 15.059 42.867 35zm-69.117-52.5h-105v-35h105zm201.25-61.25c0 4.8281-3.9297 8.75-8.75 8.75h-490c-4.8203 0-8.75-3.9219-8.75-8.75v-262.5c0-4.8281 3.9297-8.75 8.75-8.75h43.75v253.75c0 4.8398 3.9219 8.75 8.75 8.75h385c4.8281 0 8.75-3.9102 8.75-8.75v-253.75h43.75c4.8203 0 8.75 3.9219 8.75 8.75z" />
        <path d="m183.75 43.75h17.5v17.5h-17.5z" />
        <path d="m218.75 43.75h17.5v17.5h-17.5z" />
        <path d="m253.75 43.75h17.5v17.5h-17.5z" />
        <path d="m288.75 43.75h227.5v17.5h-227.5z" />
        <path d="m428.75 183.75h-1.7656c-8.1289-39.891-43.48-70-85.734-70h-70c-48.246 0-87.5 39.254-87.5 87.5s39.254 87.5 87.5 87.5h1.7656c8.1289 39.891 43.48 70 85.734 70h70c48.246 0 87.5-39.254 87.5-87.5s-39.254-87.5-87.5-87.5zm-7.3594 52.5h7.3594c19.301 0 35 15.699 35 35s-15.699 35-35 35h-70c-12.898 0-24.062-7.0859-30.137-17.5h12.637c35.805 0 66.613-21.641 80.141-52.5zm-220.14-35c0-38.598 31.402-70 70-70h70c38.598 0 70 31.402 70 70s-31.402 70-70 70h-17.5c0-6.4062 1.8555-12.328 4.8633-17.5h12.637c28.953 0 52.5-23.547 52.5-52.5s-23.547-52.5-52.5-52.5h-70c-28.953 0-52.5 23.547-52.5 52.5s23.547 52.5 52.5 52.5h1.7656c-1.1523 5.6523-1.7656 11.508-1.7656 17.5-38.598 0-70-31.402-70-70zm77.359 35h-7.3594c-19.301 0-35-15.699-35-35s15.699-35 35-35h70c12.898 0 24.062 7.0859 30.137 17.5h-12.637c-35.805 0-66.613 21.641-80.141 52.5zm150.14 105h-70c-38.598 0-70-31.402-70-70s31.402-70 70-70h17.5c0 6.4062-1.8555 12.328-4.8633 17.5h-12.637c-28.953 0-52.5 23.547-52.5 52.5s23.547 52.5 52.5 52.5h70c28.953 0 52.5-23.547 52.5-52.5s-23.547-52.5-52.5-52.5h-1.7656c1.1523-5.6523 1.7656-11.508 1.7656-17.5 38.598 0 70 31.402 70 70s-31.402 70-70 70z" />
      </g>
    </svg>
  );
}

export default PCInterfaceIcon;
