import React from "react";

function StackedBooksIcon() {
  return (
    <svg
      width="150%"
      height="150%"
      version="1.1"
      viewBox="0 0 700 700"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="none"
      stroke="#f3f3f3"
      strokeWidth="16"
      strokeLinecap="round"
    >
      <g>
        <path d="M47.5 376.189v31.834a5 5 0 005 5h407a5 5 0 005-5v-31.834"></path>
        <path d="M46.3 108.36H27.5c-11.05 0-20 8.95-20 20v305.28c0 11.05 8.95 20 20 20h76.81m361-345.28h19.19c11.05 0 20 8.95 20 20v305.28c0 11.05-8.95 20-20 20H288.94c-3.75 0-7.26-1.68-9.79-4.45-.32-.34-.64-.68-.97-1.01-5.68-5.67-13.52-9.18-22.18-9.18-9.25 0-17.57 4-23.3 10.38-2.48 2.75-6.06 4.26-9.77 4.26h-82.92"></path>
        <path d="M464.5 223.35v157.41h-33.22c-6 0-16.66-1.49-28.35-3.51m-34.36-6.65c-11.13-2.33-19.13-4.13-19.19-4.14-37.6-7.94-80.48 16.13-93.38 46.56V111.73c12.9-30.43 55.78-49.5 93.38-41.57.15.03 63.34 14.32 81.9 14.31 7.42 0 18.1-.67 27.03-2.76 3.16-.74 6.19 1.61 6.19 4.85v101.09M209.638 73.117c21.008 6.894 38.816 20.81 46.362 38.613m0 301.292c-12.898-30.431-55.78-54.504-93.378-46.567-.147.026-63.343 14.317-81.901 14.308H47.5V86.562c0-3.245 3.034-5.595 6.193-4.854C62.624 83.8 73.3 84.472 80.72 84.472c10.517.005 35.369-4.582 54.958-8.564"></path>
        <path d="M208.56 130.86v14.48c0 3.73-3.93 6.15-7.25 4.47l-28.61-14.46-28.61 14.46c-3.32 1.68-7.25-.74-7.25-4.47v-67.2c0-7.36 5.15-13.72 12.35-15.27 3.73-.8 7.4-1.6 10.93-2.39.17-.04.44-.1.44-.1 6.34-1.34 12.95-2.02 19.65-2.02 5.02 0 10.08.39 15.1 1.14 7.63 1.14 13.25 7.73 13.25 15.44v20.22m82.464 55.928h138.452m-138.452 49.574h138.452m-138.452 49.574h138.452M291.024 299.81h138.452m-138.452 0h138.452m-208.5-99.148H82.524m138.452 49.574H82.524m138.452 49.574H82.524m138.452 0H82.524"></path>
      </g>
    </svg>
  );
}

export default StackedBooksIcon;
