import { professors } from "./BalkanStudies";
import { professors as professors2 } from "./Business";
import { professors as professors3 } from "./Informatics";
import { professors as professors4 } from "./EuropeanStudies";
import { professors as professors5 } from "./Finance";
import { professors as professors6 } from "./Education";
import { professors as professors7 } from "./Economics";
import { professors as professors8 } from "./Music";


const allProfessors = [
    professors,
    professors2,
    professors3,
    professors4,
    professors5,
    professors6,
    professors7,
    professors8

]

export default allProfessors;

